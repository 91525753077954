/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  // Menu toggle
  $('.stm-menu-toggle').on('click', function() {
    $('.stm-mobile-menu-unit').toggle();
  });

  // Fancybox
  $("a#single_image").fancybox();

  // Filtre galerie accueil
  // $('.galerie-accueil button.cat').on('click', function () {
  //   $.ajax({
  //      url: "http://localhost/ucs-rugby/?category=" + $(this).attr('id')
  //   }).done(function (html) {
  //       $('.galerie-accueil').html($(html).find('.galerie-accueil > .row'))
  //   });
  // });
  $('.galerie-accueil button.cat').on('click', function () {
    var active = $(this).attr('id');
    $('.galerie-accueil button.cat').removeClass('active');
    $(this).addClass('active');
    $('.galerie-accueil .content .active').removeClass('active');
    $('.galerie-accueil .content .'+active).addClass('active');
  });

  ///// Carousel equipe /////
  $('.owl-carousel').owlCarousel({
    margin:30,
    loop:true,
    items:4,
    nav:true,
    dots:false,
    responsive : {
      0 : {
        items:1
      },
      450 : {
        items:2
      },
      750 : {
        items:3
      },
      950 : {
        items:4
      }
    }
  });

  ///// Equipe pro /////
  $('.proteam .filter li').on('click', function() {
    console.log('click');
    $('.proteam .content .active').removeClass('active');
    $('.proteam .filter li.active').removeClass('active');
    var active = $(this).attr('data-active');
    $('.proteam .content .'+active).addClass('active');
    $(this).addClass('active');
  });

  ///// Sidebar scrollto /////
  $(".ucssidebar .filter a").click(function(e) {
    e.preventDefault();
    var id = $(this).attr('href');
    $([document.documentElement, document.body]).animate({
        scrollTop: $(id).offset().top - 250
    }, 1000);
  });

  ///// Share Facebook /////
  var url = window.location.href;

  $('#fb-share-button').on('click', function() {
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + url,
          'facebook-share-dialog',
          'width=800,height=600'
      );
      return false;
  });

  ///// Galerie Portfolio /////
  $('.portfolio-filter a').on('click', function(e) {
    e.preventDefault();
    $('.portfolio-filter li').removeClass('active');
    $('.portfolio-container .col-lg-12').removeClass('active');
    var active = $(this).closest('li').attr('class');
    $('.portfolio-filter li.'+active).addClass('active');
    $('.portfolio-container .'+active).addClass('active');
  });

  ///// MENU COLOR /////
  $(window).scroll(function (event) {
    var scroll = $(window).scrollTop();
    if(scroll > 0) {
      $('.stm-header.stm-header-second .stm-header-inner .stm-main-menu .stm-main-menu-unit .kos-header-menu').css('background-color','rgba(0, 42, 74, 1)');
      $('ul.sub-menu').css('background-color','rgba(0, 42, 74, 1)');
    } else {
      $('.stm-header.stm-header-second .stm-header-inner .stm-main-menu .stm-main-menu-unit .kos-header-menu').css('background-color','rgba(0, 42, 74, 0.85)');
      $('ul.sub-menu').css('background-color','rgba(0, 42, 74, 0.85)');
    }
  });

})(jQuery); // Fully reference jQuery after this point.
